import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Skeleton,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { useNavigate } from "react-router-dom";
import "./HomePage.scss";
import Calendar from "react-calendar";
import { InfoItem } from "./components/InfoItem";
import { FaPallet } from "react-icons/fa";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { storageService } from "../../services/storage-service";
import { useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";

const Page = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [pallets, loadingPallets] = useDataFetcher({
    serviceCall: () => storageService.getAllPallets(),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [showCalendar, setShowCalendar] = useState(true);
  const [search, setSearch] = useState("");

  const getPageContent = () => {
    return (
      <Box w={"100%"} padding={"2rem"} paddingTop={"6rem"} overflowY={"auto"}>
        <Flex className="HomePage">
          {loadingPallets ? (
            <Flex
              w={"100%"}
              paddingTop={"2rem"}
              gap={"2rem"}
              flexDir={"column"}
            >
              <Skeleton w={"100%"} h={"52rem"} />
              {new Array(3).fill(0).map(() => {
                return <Skeleton w={"100%"} h={"10rem"} />;
              })}
            </Flex>
          ) : (
            <>
              <Flex w={"100%"}>
                <Button
                  variant={"grey"}
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  {showCalendar ? "Hide" : "Show"} Calendar
                </Button>
              </Flex>
              {showCalendar && (
                <Calendar
                  className="Calendar"
                  tileClassName="CalendarTile"
                  calendarType="gregory"
                  showNavigation={true}
                  tileContent={(data) => {
                    const currentPallets = pallets?.filter((pallet) => {
                      return (
                        pallet.current &&
                        new Date(pallet.current.entry_date).toDateString() ===
                          data.date.toDateString()
                      );
                    });
                    return (
                      <Stack
                        direction={"column"}
                        spacing={2}
                        overflowY={"auto"}
                        w={"100%"}
                      >
                        {currentPallets &&
                          currentPallets.map((pallet) => {
                            return (
                              <Stack
                                direction={"column"}
                                spacing={2}
                                onClick={() =>
                                  navigate(`/edit-pallet/${pallet.unit_id}`)
                                }
                              >
                                <InfoItem
                                  icon={<FaPallet color="#DABF75" />}
                                  name={pallet.unit_id}
                                />
                              </Stack>
                            );
                          })}
                      </Stack>
                    );
                  }}
                />
              )}
              <Flex w={"100%"}>
                <InputGroup
                  w={"20rem"}
                  border={"1.5px solid black"}
                  borderRadius={"0.65rem"}
                >
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search by palet..."
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </InputGroup>
              </Flex>
              <Flex
                flexDir={"column"}
                w={"100%"}
                gap={"2rem"}
                paddingBottom={"6rem"}
              >
                {pallets &&
                  pallets?.map((pallet) => {
                    if (
                      pallet.current &&
                      (pallet.unit_id.includes(search) ||
                        pallet.current.client_id.includes(search))
                    ) {
                      return (
                        <Flex
                          padding={"2rem"}
                          border={"2px solid black"}
                          w={"100%"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Flex
                            gap={"4rem"}
                            alignItems={"center"}
                            h={"100%"}
                            margin={"1rem"}
                          >
                            <Text fontSize={"2xl"} fontWeight={"bold"}>
                              {pallet.unit_id}
                            </Text>
                            <Divider
                              orientation="vertical"
                              borderColor={"black"}
                              h={"100%"}
                            />
                            <Flex
                              flexDir={"column"}
                              justifyContent={"space-between"}
                              h={"100%"}
                            >
                              <Text fontSize={"lg"}>
                                {pallet.current.client_id}
                              </Text>
                              <Text fontSize={"lg"}>
                                {pallet.current.entry_date}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex gap={"1rem"}>
                            <Button
                              bg={"#AE1000"}
                              color={"white"}
                              _hover={{
                                background: "#AE1000",
                              }}
                              fontSize={"lg"}
                              onClick={() =>
                                navigate(`/discharge-pallet/${pallet.unit_id}`)
                              }
                            >
                              Discharge
                            </Button>
                            <Button
                              bg={"#DABF75"}
                              color={"white"}
                              _hover={{
                                background: "#DABF75",
                              }}
                              fontSize={"lg"}
                              onClick={() =>
                                navigate(`/edit-pallet/${pallet.unit_id}`)
                              }
                            >
                              See More
                            </Button>
                          </Flex>
                        </Flex>
                      );
                    }
                  })}
              </Flex>
            </>
          )}
        </Flex>
      </Box>
    );
  };

  return getPageContent();
};

export const HomePage = withAuthProtection(Page);
