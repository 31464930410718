import { IStorage, IStorageCurrent } from "../data/product";
import { BaseService } from "./api-clients/base-service";
import { BearerTokenApiClient } from "./api-clients/bearer-token-api-client";

export class StorageService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, "storage");
  }

  addStorageElement(storage: IStorageCurrent, unitId: string): Promise<string> {
    const storageElement: IStorage = {
      unit_id: unitId,
      current: storage,
      past: [],
    };
    const url = this.url("/add-storage");
    return this.apiClient.post(url, storageElement);
  }

  updateStorageElement(
    storage: IStorageCurrent,
    unitId: string
  ): Promise<string> {
    const storageElement: IStorage = {
      unit_id: unitId,
      current: storage,
      past: [],
    };
    const url = this.url("/update-storage");
    return this.apiClient.post(url, storageElement);
  }

  dischargeStorageElement(
    unitId: string,
    dischargeDate: string,
    dischageSignature: string
  ): Promise<string> {
    const url = this.url("/discharge-storage");
    return this.apiClient.post(url, {
      unit_id: unitId,
      discharge_date: dischargeDate,
      discharge_signature: dischageSignature,
    });
  }

  getAllPallets(): Promise<IStorage[]> {
    const url = this.url("/list");
    return this.apiClient.get(url);
  }

  getStorageByUnitId(unitId?: string): Promise<IStorage> {
    const url = this.url(`/unit-id/${unitId}`);
    return this.apiClient.get(url);
  }

  getPastStorageByEmail(email?: string): Promise<IStorage[]> {
    const url = this.url(`/past/${email}`);
    return this.apiClient.get(url);
  }

  getStorage(email?: string): Promise<IStorage[]> {
    const url = this.url(`/email/${email}`);
    return this.apiClient.get(url);
  }
}
export const storageService = new StorageService(new BearerTokenApiClient());
