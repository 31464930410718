import {
  Box,
  Text,
  Flex,
  Input,
  Button,
  useToast,
  Skeleton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Icon,
  Image,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { useEffect, useRef, useState } from "react";
import { storageService } from "../../services/storage-service";
import { IStorage } from "../../data/product";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { useNavigate } from "react-router-dom";
import { IoCloudOfflineOutline } from "react-icons/io5";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";
import "./DischargePalletPage.scss";
import React from "react";

const Page = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [dischargeDate, setDischargeDate] = useState(
    new Date().toISOString().slice(0, -1)
  );
  const unitId = window.location.href.toString().split("discharge-pallet/")[1];
  const [dischargeUnitId, setDischargeUnitId] = useState(
    unitId !== "discharge" ? unitId : ""
  );
  const [pallet, loadingPallet] = useDataFetcher({
    serviceCall: () => storageService.getStorageByUnitId(unitId),
    conditionForExecution: unitId !== "discharge",
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [pallets, loadingPallets] = useDataFetcher({
    serviceCall: () => storageService.getAllPallets(),
    conditionForExecution: unitId === "discharge",
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [signature, setSignature] = useState("");
  const sigCanvas = useRef<ReactSignatureCanvas>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSignatureOpen,
    onOpen: onSignatureOpen,
    onClose: onSignatureClose,
  } = useDisclosure();
  const finalRef = React.useRef(null);
  const navigate = useNavigate();

  const handleDischarge = async () => {
    setIsLoading(true);
    try {
      if (unitId !== "discharge") {
        await storageService.dischargeStorageElement(
          unitId,
          dischargeDate,
          signature
        );
        toast({
          title: "Pallet discharged successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        navigate("/home");
      } else {
        const foundPallet = pallets?.find(
          (pallet) => pallet.unit_id === dischargeUnitId
        );
        if (foundPallet && foundPallet.current) {
          await storageService.dischargeStorageElement(
            dischargeUnitId,
            dischargeDate,
            signature
          );
          toast({
            title: "Pallet discharged successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          setDischargeUnitId("");
          setSignature("");
          setIsLoading(false);
          return;
        } else {
          toast({
            title: "There is no pallet with this unit_id",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          setIsLoading(false);
          return;
        }
      }
    } catch (e: any) {
      toast({
        title: e.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    setIsLoading(false);
  };

  const getPageContent = (pallet: IStorage | undefined) => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="EditPalletPage"
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "2rem", xl: "2rem" }}
          maxW={"1500px"}
        >
          <Text fontSize={"3xl"} fontWeight={"bold"}>
            Pallet Information
          </Text>
          <Box display={unitId !== "discharge" ? "inline-block" : "none"}>
            <Skeleton isLoaded={!loadingPallet}>
              <Input
                fontSize={"xl"}
                value={pallet?.current?.client_id || ""}
                isDisabled
                variant={"filled"}
                placeholder="Client Email"
                height={"3rem"}
                readOnly
              />
            </Skeleton>
          </Box>
          <Flex gap={"2rem"}>
            <Skeleton isLoaded={!loadingPallet} w={"100%"}>
              <Input
                isDisabled={unitId !== "discharge"}
                fontSize={"xl"}
                value={dischargeUnitId}
                variant={"filled"}
                placeholder="Unit"
                height={"3rem"}
                onChange={(event) => setDischargeUnitId(event.target.value)}
                readOnly={unitId !== "discharge"}
              />
            </Skeleton>
            <Skeleton isLoaded={!loadingPallet} w={"100%"}>
              <Input
                fontSize={"xl"}
                type="datetime-local"
                value={dischargeDate}
                onChange={(event) => setDischargeDate(event.target.value)}
                variant={"filled"}
                placeholder="Entry Date"
                height={"3rem"}
                readOnly
              />
            </Skeleton>
          </Flex>
          <Flex justifyContent={"start"} gap={"2rem"}>
            <Button
              padding={"2.4rem"}
              w={"min-content"}
              color={"white"}
              bg={"#DABF75"}
              onClick={onSignatureOpen}
            >
              Open Signature Pad
            </Button>
            {signature && (
              <Box border={"1px solid black"} w={"125px"} h={"75px"}>
                <Image
                  src={signature}
                  w={"100%"}
                  h={"100%"}
                  objectFit={"contain"}
                />
              </Box>
            )}
            <Modal
              isCentered
              onClose={onSignatureClose}
              isOpen={isSignatureOpen}
              motionPreset="slideInBottom"
            >
              <ModalOverlay />
              <ModalContent
                maxW={"1000px"}
                w={"80%"}
                h={"80%"}
                maxHeight={"600px"}
              >
                <ModalHeader>Signature</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{
                      className: "sigCanvas",
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      setSignature(
                        sigCanvas.current
                          ?.getTrimmedCanvas()
                          .toDataURL("image/png") || ""
                      );
                      onSignatureClose();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    mr={3}
                    onClick={() => {
                      setSignature("");
                      sigCanvas.current?.clear();
                    }}
                  >
                    Clear
                  </Button>
                  <Button mr={3} onClick={onSignatureClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
          <Flex w={"100%"} justifyContent={"end"} paddingBottom={"2rem"}>
            <Button
              w={"100%"}
              _hover={{
                background: "#DABF75",
              }}
              height={"5rem"}
              fontSize={"xl"}
              bg={"#DABF75"}
              color={"white"}
              isDisabled={!dischargeUnitId || !signature || isLoading}
              onClick={() => onOpen()}
            >
              {`Discharge ${isLoading ? "(In Progress...)" : ""}`}
            </Button>
            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
              <ModalOverlay bg="blackAlpha.300" />
              <ModalContent>
                <ModalHeader>Confirm Discharge</ModalHeader>
                <ModalCloseButton />
                <ModalBody>This will discharge the current pallet.</ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onClose();
                      handleDischarge();
                    }}
                  >
                    Discharge
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </Box>
      </Box>
    );
  };

  const getEmptyState = () => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="EditPalletPage"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={{ base: "2rem", xl: "2rem" }}
          maxW={"1500px"}
        >
          <Flex flexDir={"column"} alignItems={"center"} gap={"0.5rem"}>
            <Icon boxSize={12} as={IoCloudOfflineOutline} />
            <Text>There is no data to display</Text>
          </Flex>
        </Box>
      </Box>
    );
  };

  const getContent = () => {
    return (pallet && pallet.current) || unitId === "discharge"
      ? getPageContent(pallet)
      : getEmptyState();
  };

  const getLoadingState = () => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="EditPalletPage"
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "2rem", xl: "2rem" }}
          maxW={"1500px"}
        >
          <Text fontSize={"3xl"} fontWeight={"bold"}>
            Pallet Information
          </Text>
          <Box>
            <Skeleton w={"100%"} h={"3rem"} />
          </Box>
          <Flex gap={"2rem"}>
            <Skeleton w={"100%"} h={"3rem"} />
            <Skeleton w={"100%"} h={"3rem"} />
          </Flex>
          <Flex w={"100%"} justifyContent={"end"} paddingBottom={"2rem"}>
            <Button
              w={"100%"}
              _hover={{
                background: "#DABF75",
              }}
              height={"5rem"}
              fontSize={"xl"}
              bg={"#DABF75"}
              color={"white"}
              isDisabled
              onClick={() => handleDischarge()}
            >
              Discharge
            </Button>
          </Flex>
        </Box>
      </Box>
    );
  };

  return !loadingPallet && !loadingPallets ? getContent() : getLoadingState();
};

export const DischargePalletPage = withAuthProtection(Page);
