import { Box, Text, Flex, Grid, Skeleton } from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { useState } from "react";
import data from "../../../config/config.json";

const styleTemplateColumns = {
  base: "repeat(1, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(2, 1fr)",
};

const styleColumnGap = {
  base: "1rem",
  xl: "2.5rem",
  "2xl": "4rem",
};

const Page = () => {
  const [isLoading, setIsLoading] = useState(true);

  const renderVideos = () => {
    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {data.trainingVideos.map((video, index) => {
          return (
            <Box className="video-responsive" key={index + " " + video}>
              <iframe
                width="100%"
                height="480"
                onLoad={() => setIsLoading(false)}
                src={`https://www.youtube.com/embed/${video}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ display: isLoading ? "none" : "block" }}
                title="Embedded youtube"
              />
            </Box>
          );
        })}
      </Grid>
    );
  };

  const renderSkeletons = () => {
    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {new Array(4).fill(0).map(() => {
          return <Skeleton width="100%" height="480" />;
        })}
      </Grid>
    );
  };

  const getPageContent = () => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="ClientsPage"
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "2rem", xl: "4rem" }}
          maxW={"1500px"}
        >
          <Flex alignItems={"center"} gap={"1rem"}>
            <Text fontSize="5xl" fontWeight={700}>
              Training
            </Text>
          </Flex>
          <Box>
            <Flex flexDir={"column"} gap={"2rem"} paddingBottom={"2rem"}>
              {isLoading && renderSkeletons()}
              {renderVideos()}
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  };

  return getPageContent();
};

export const TrainingPage = withAuthProtection(Page);
