import {
  Box,
  Text,
  Flex,
  Input,
  Textarea,
  Button,
  Image,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Skeleton,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import "./ReceivePalletPage.scss";
import { storageService } from "../../services/storage-service";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { IClient, IStorageDocument } from "../../data/product";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";
import { clientsService } from "../../services/clients-service";

const Page = () => {
  const toast = useToast();
  const [clientEmail, setClientEmail] = useState("");
  const [description, setDescription] = useState("");
  const [isClientDropdownOpen, setClientDropdownOpen] = useState(false);
  const [entryDate, setEntryDate] = useState(
    new Date().toISOString().slice(0, -1)
  );
  const [unit, setUnit] = useState("");
  const [images, setImages] = useState<string[]>([]);
  const [documents, setDocuments] = useState<IStorageDocument[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pallets, loadingPallets] = useDataFetcher({
    serviceCall: () => storageService.getAllPallets(),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [filteredOptions, setFilteredOptions] = useState<IClient[]>([]);
  const [clients, loadingClients] = useDataFetcher({
    serviceCall: () => clientsService.getClients(),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [signature, setSignature] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sigCanvas = useRef<ReactSignatureCanvas>(null);
  const searchBarRef = useRef<HTMLDivElement | null>(null);

  const toBase64 = (file: File) =>
    new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString());
      reader.onerror = reject;
    });

  const removeDocument = (index: number) => {
    const tempDocuments = documents.filter(
      (document) => document.documentName !== documents[index].documentName
    );
    setDocuments(tempDocuments);
  };

  const removeImage = (index: number) => {
    const tempImages = images.filter((image) => image !== images[index]);
    setImages(tempImages);
  };

  const handleClientInputChange = (event: any) => {
    const value = event.target.value;
    setClientEmail(value);

    if (value.length > 0) {
      const filtered = clients?.filter((option) => {
        return (
          option.businessEmail.toLowerCase().includes(value.toLowerCase()) ||
          option.name.toLowerCase().includes(value.toLowerCase()) ||
          option.lastName.toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilteredOptions(filtered || []);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleClientOptionClick = (option: IClient) => {
    setClientEmail(option.businessEmail);
    setFilteredOptions([]);
    setClientDropdownOpen(false);
  };

  const handleInputClick = () => {
    if (!isClientDropdownOpen) {
      setClientDropdownOpen(true);
    }
  };

  const handleInputFocus = () => {
    if (!isClientDropdownOpen) {
      setClientDropdownOpen(true);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!clientEmail.match(validRegex)) {
        toast({
          title: "Client Email is not a valid email",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
        return;
      }
      const foundPallet = pallets?.find((pallet) => pallet.unit_id === unit);
      if (foundPallet && foundPallet.current) {
        toast({
          title: "There is already a pallet with this unit_id",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
        return;
      }
      const storageElement = {
        client_id: clientEmail,
        description: description,
        entry_date: entryDate,
        photo: images,
        document: documents,
        signature: signature,
        status: "BUSY",
      };
      if (foundPallet) {
        await storageService.updateStorageElement(storageElement, unit);
      } else {
        await storageService.addStorageElement(storageElement, unit);
      }
      toast({
        title: "New pallet added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setClientEmail("");
      setUnit("");
      setDescription("");
      setEntryDate(new Date().toISOString().slice(0, -1));
      setImages([]);
      setDocuments([]);
      setSignature("");
    } catch (e: any) {
      toast({
        title: e.message,
        description: "check file size",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setClientDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getPageContent = () => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="ReceivePalletPage"
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "2rem", xl: "2rem" }}
          maxW={"1500px"}
        >
          <Text fontSize={"3xl"} fontWeight={"bold"}>
            Pallet Information
          </Text>
          <Skeleton isLoaded={!loadingClients}>
            <Box className="search-bar" ref={searchBarRef}>
              <Input
                fontSize={"xl"}
                value={clientEmail}
                onChange={handleClientInputChange}
                onClick={handleInputClick}
                onFocus={handleInputFocus}
                variant={"filled"}
                placeholder="Client Email"
                height={"3rem"}
              />
              {isClientDropdownOpen && filteredOptions.length > 0 && (
                <ul className="search-options">
                  {filteredOptions.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => handleClientOptionClick(option)}
                    >
                      {option.name +
                        " " +
                        option.lastName +
                        " - " +
                        option.businessEmail}
                    </li>
                  ))}
                </ul>
              )}
            </Box>
          </Skeleton>
          <Flex gap={"2rem"}>
            <Input
              fontSize={"xl"}
              value={unit}
              onChange={(event) => setUnit(event.target.value)}
              variant={"filled"}
              placeholder="Unit"
              height={"3rem"}
            />
            <Input
              fontSize={"xl"}
              type="datetime-local"
              value={entryDate}
              onChange={(event) => setEntryDate(event.target.value)}
              variant={"filled"}
              placeholder="Entry Date"
              height={"3rem"}
            />
          </Flex>
          <Flex gap={"2rem"} flexDir={"column"} paddingTop={"2rem"}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>
              Description
            </Text>
            <Textarea
              fontSize={"xl"}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              variant={"filled"}
              size="lg"
              height={"20rem"}
            />
          </Flex>
          <Flex gap={"2rem"} flexDir={"column"} paddingTop={"2rem"}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>
              Photos
            </Text>
            <Flex justifyContent={"space-between"}>
              <Button
                bg={"#DABF75"}
                w={"100px"}
                h={"250px"}
                onClick={() =>
                  document.getElementById("HomePageImageUploader")?.click()
                }
              >
                <Input
                  id="HomePageImageUploader"
                  type="file"
                  hidden
                  onClick={(event) => (event.currentTarget.value = "")}
                  onChange={async (event) => {
                    if (event.target.files && event.target.files?.length > 0) {
                      const temp = await toBase64(event.target.files[0]);
                      setImages([...images, temp]);
                    }
                  }}
                />
                <AddIcon color={"white"} />
              </Button>
              <Box w={"25%"} h={"250px"} bg={"#edf2f6"} position={"relative"}>
                {images.length > 0 && (
                  <>
                    <Image className="ReceivePalletPageImage" src={images[0]} />
                    <Button
                      className="ReceivePalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeImage(0)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Box>
              <Box w={"25%"} h={"250px"} bg={"#edf2f6"} position={"relative"}>
                {images.length > 1 && (
                  <>
                    <Image className="ReceivePalletPageImage" src={images[1]} />
                    <Button
                      className="ReceivePalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeImage(1)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Box>
              <Box w={"25%"} h={"250px"} bg={"#edf2f6"} position={"relative"}>
                {images.length > 2 && (
                  <>
                    <Image className="ReceivePalletPageImage" src={images[2]} />
                    <Button
                      className="ReceivePalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeImage(2)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Box>
            </Flex>
          </Flex>
          <Flex gap={"2rem"} flexDir={"column"} paddingTop={"2rem"}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>
              Documents
            </Text>
            <Flex justifyContent={"space-between"}>
              <Button
                bg={"#DABF75"}
                w={"100px"}
                h={"250px"}
                onClick={() =>
                  document.getElementById("HomePageFileUploader")?.click()
                }
              >
                <Input
                  id="HomePageFileUploader"
                  type="file"
                  hidden
                  onClick={(event) => (event.currentTarget.value = "")}
                  onChange={async (event) => {
                    if (event.target.files && event.target.files?.length > 0) {
                      const temp = await toBase64(event.target.files[0]);
                      setDocuments([
                        ...documents,
                        {
                          document: temp,
                          documentName: event.target.files[0].name,
                        },
                      ]);
                    }
                  }}
                />
                <AddIcon color={"white"} />
              </Button>
              <Flex className="ReceivePalletPageDocumentContainer">
                {documents.length > 0 && (
                  <>
                    <Text fontSize={"xl"}>{documents[0].documentName}</Text>
                    <Button
                      className="ReceivePalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeDocument(0)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Flex>
              <Flex className="ReceivePalletPageDocumentContainer">
                {documents.length > 1 && (
                  <>
                    <Text fontSize={"xl"}>{documents[1].documentName}</Text>
                    <Button
                      className="ReceivePalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeDocument(1)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Flex>
              <Flex className="ReceivePalletPageDocumentContainer">
                {documents.length > 2 && (
                  <>
                    <Text fontSize={"xl"}>{documents[2].documentName}</Text>
                    <Button
                      className="ReceivePalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeDocument(2)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent={"start"} gap={"2rem"}>
            <Button
              padding={"2.4rem"}
              w={"min-content"}
              color={"white"}
              bg={"#DABF75"}
              onClick={onOpen}
            >
              Open Signature Pad
            </Button>
            {signature && (
              <Box border={"1px solid black"} w={"125px"} h={"75px"}>
                <Image
                  src={signature}
                  w={"100%"}
                  h={"100%"}
                  objectFit={"contain"}
                />
              </Box>
            )}
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
            >
              <ModalOverlay />
              <ModalContent
                maxW={"1000px"}
                w={"80%"}
                h={"80%"}
                maxHeight={"600px"}
              >
                <ModalHeader>Signature</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{
                      className: "sigCanvas",
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      setSignature(
                        sigCanvas.current
                          ?.getTrimmedCanvas()
                          .toDataURL("image/png") || ""
                      );
                      onClose();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    mr={3}
                    onClick={() => {
                      setSignature("");
                      sigCanvas.current?.clear();
                    }}
                  >
                    Clear
                  </Button>
                  <Button mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
          <Flex w={"100%"} justifyContent={"end"} paddingBottom={"2rem"}>
            <Button
              w={"100%"}
              _hover={{
                background: "#DABF75",
              }}
              height={"5rem"}
              fontSize={"xl"}
              bg={"#DABF75"}
              color={"white"}
              isDisabled={
                !clientEmail ||
                !unit ||
                !entryDate ||
                !description ||
                !signature ||
                isLoading ||
                loadingPallets
              }
              onClick={() => handleSubmit()}
            >
              {`Submit ${isLoading ? "(In Progress...)" : ""}`}
            </Button>
          </Flex>
        </Box>
      </Box>
    );
  };

  return getPageContent();
};

export const ReceivePalletPage = withAuthProtection(Page);
