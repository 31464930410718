import {
  Box,
  Text,
  Flex,
  Grid,
  Skeleton,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { storageService } from "../../services/storage-service";
import { PaletCard } from "./components/PaletCard";
import { IoCloudOfflineOutline } from "react-icons/io5";
import "./ClientDetailPage.scss";
import { clientsService } from "../../services/clients-service";
import { useEffect } from "react";

const styleTemplateColumns = {
  base: "repeat(1, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(3, 1fr)",
};

const styleColumnGap = {
  base: "1rem",
  xl: "2.5rem",
  "2xl": "5rem",
};

const Page = () => {
  const toast = useToast();
  const email = window.location.href.toString().split("client/")[1];
  const [client, loadingClient] = useDataFetcher({
    serviceCall: () => clientsService.getClientByEmail(email),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [pastStorage, loadingPastStorage] = useDataFetcher({
    serviceCall: () => storageService.getPastStorageByEmail(email),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [currentStorage, loadingCurrentStorage] = useDataFetcher({
    serviceCall: () => storageService.getStorage(email),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });

  const renderCurrentPalets = () => {
    if (!currentStorage || currentStorage.length === 0) {
      return (
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          gap={"0.5rem"}
          paddingTop={"2rem"}
        >
          <Icon boxSize={12} as={IoCloudOfflineOutline} />
          <Text>There is no data to display</Text>
        </Flex>
      );
    }

    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {currentStorage.map((pallet) => {
          if (pallet.current) {
            return (
              <PaletCard
                key={pallet.unit_id}
                unit_id={pallet.unit_id}
                entry_date={pallet.current.entry_date}
                description={pallet.current.description}
                signature={pallet.current.signature}
                photos={pallet.current.photo || ""}
                current
              />
            );
          }
        })}
      </Grid>
    );
  };

  const renderPastPalets = () => {
    if (!pastStorage || pastStorage.length === 0) {
      return (
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          gap={"0.5rem"}
          paddingTop={"2rem"}
        >
          <Icon boxSize={12} as={IoCloudOfflineOutline} />
          <Text>There is no data to display</Text>
        </Flex>
      );
    }

    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {pastStorage.map((pallet) => {
          if (pallet.past) {
            return pallet.past.map((past) => {
              if (past.client_id === email) {
                return (
                  <PaletCard
                    key={`${pallet.unit_id} ${past.entry_date}`}
                    unit_id={pallet.unit_id}
                    entry_date={past.discharge_date}
                    description={past.description}
                    signature={past.discharge_signature}
                    photos={past.photo || ""}
                  />
                );
              }
            });
          }
        })}
      </Grid>
    );
  };

  const renderSkeletons = () => {
    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {new Array(6).fill(0).map(() => {
          return <Skeleton h={"28rem"} w={"100%"} />;
        })}
      </Grid>
    );
  };

  const getPageContent = () => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="HistoryPage"
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "2rem", xl: "4rem" }}
          maxW={"1500px"}
        >
          <Box>
            <Skeleton isLoaded={!loadingClient}>
              <Text fontSize="5xl" fontWeight={700}>
                {client && client.length > 0 && client[0].name
                  ? `${client[0].name} ${client[0].lastName}`
                  : email}
              </Text>
            </Skeleton>
          </Box>
          <Box>
            <Text fontSize="3xl" fontWeight={700} marginBottom={"1rem"}>
              Current Usage
            </Text>
            <Flex flexDir={"column"} gap={"2rem"} paddingBottom={"2rem"}>
              {loadingCurrentStorage ? (
                renderSkeletons()
              ) : (
                <>{renderCurrentPalets()}</>
              )}
            </Flex>
          </Box>
          <Box>
            <Text fontSize="3xl" fontWeight={700} marginBottom={"1rem"}>
              Past Usage
            </Text>
            <Flex flexDir={"column"} gap={"2rem"} paddingBottom={"2rem"}>
              {loadingPastStorage ? (
                renderSkeletons()
              ) : (
                <>{renderPastPalets()}</>
              )}
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  };

  return getPageContent();
};

export const ClientDetailPage = withAuthProtection(Page);
