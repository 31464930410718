import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { HomePage } from "./Pages/HomePage/HomePage";
import { LoginPage } from "./Pages/LoginPage/LoginPage";
import { SignupPage } from "./Pages/SignupPage/SignupPage";
import { LoginCallbackPage } from "./Pages/LoginCallBackPage/LoginCallbackPage";
import { PageNotFoundPage } from "./Pages/PageNotFound/PageNotFound";
import { EmptyLayout } from "./Layouts/EmptyLayout/EmptyLayout";
import { MainLayout } from "./Layouts/MainLayout/MainLayout";
import { ReceivePalletPage } from "./Pages/ReceivePalletPage/ReceivePalletPage";
import { EditPalletPage } from "./Pages/EditPalletPage/EditPalletPage";
import { DischargePalletPage } from "./Pages/DischargePalletPage/DischargePalletPage";
import { ClientsPage } from "./Pages/ClientsPage/ClientsPage";
import { ClientDetailPage } from "./Pages/ClientDetailPage/ClientDetailPage";
import { TrainingPage } from "./Pages/TrainingPage/TrainingPage";

export const Kernel = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="home" element={<HomePage />} />
          <Route
            path="discharge-pallet/:unit_id"
            element={<DischargePalletPage />}
          />
          <Route path="edit-pallet/:unit_id" element={<EditPalletPage />} />
          <Route path="receive-pallet" element={<ReceivePalletPage />} />
          <Route path="clients" element={<ClientsPage />} />
          <Route path="client/:email" element={<ClientDetailPage />} />
          <Route path="training" element={<TrainingPage />} />
          <Route index element={<HomePage />} />
        </Route>
        <Route path="/" element={<EmptyLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="login-callback" element={<LoginCallbackPage />} />
        </Route>
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
