import { IClient } from "../data/product";
import { BaseService } from "./api-clients/base-service";
import { BearerTokenApiClient } from "./api-clients/bearer-token-api-client";

export class ClientsService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, "clients");
  }

  createClient(client: IClient): Promise<void> {
    const url = this.url("/add");
    return this.apiClient.post(url, client);
  }

  getClientByEmail(email: string): Promise<IClient[]> {
    const url = this.url(`/${email}`);
    return this.apiClient.get(url);
  }

  getClients(): Promise<IClient[]> {
    const url = this.url("/list");
    return this.apiClient.get(url);
  }
}
export const clientsService = new ClientsService(new BearerTokenApiClient());
