export const configuration = {
  cedApiKey: "6af4695fa351508316ed2f47ff55b11c408c2d8785a6965cf756a0840fe4f36a5f16edbaaa262b6ce8e200e642a31c8e88e3",
  environment: "production",
  version: "1.0.27",
  businessMetadata: {
    lat: "33.936320",
    lng: "-84.210060",
    direction: "301 Fairmont Dr Norcross GA 30071",
  },
  heroku: {
    hostingUrl: "https://api.frio.ai",
  },
  userRegistry: {
    domain: "auth.frio.ai",
    managementClientId: "FJxqPYLsXYfJJ1TS3CZDHVLTg0MrzmF9",
    managementClientSecret: "TKjIUzUWJToeJceZE9hLyeMqetPbTP93p_TXXUaFOCZC2l5PxyWQ7Wigz0qzgVOl",
    audience: "https://demy.frio.ai",
    clientId: "wewMXRvLnSbjne7nWBvoNon2HSy13VvW",
  },
};