import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { IClient } from "../../../data/product";

interface ClientCardProps {
  client: IClient;
}

export const ClientCard: FunctionComponent<ClientCardProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Flex
      bg={"white"}
      border={"1px solid black"}
      padding={"1rem"}
      gap={"1rem"}
      flexDir={"column"}
      justifyContent={"space-between"}
      width={"100%"}
      id={`client-${props.client.businessEmail}`}
    >
      <Box
        border={"1px solid black"}
        padding={"0.5rem"}
        fontWeight={600}
        w={"min-content"}
        whiteSpace={"nowrap"}
      >
        {props.client.name} {props.client.lastName}
      </Box>
      <Text>{props.client.businessEmail}</Text>
      <Text>{props.client.businessAddress}</Text>
      <Flex justifyContent={"end"}>
        <Button
          variant="grey"
          onClick={() => navigate(`/client/${props.client.businessEmail}`)}
        >
          See Pallets
        </Button>
      </Flex>
    </Flex>
  );
};
