import { Button } from "@chakra-ui/react";
import { FunctionComponent, ReactElement } from "react";

interface InfoItemProps {
  name: string;
  count?: number;
  icon: ReactElement;
}

export const InfoItem: FunctionComponent<InfoItemProps> = (props) => {
  return (
    <Button
      leftIcon={props.icon}
      variant={"outline"}
      size={"sm"}
      borderRadius={0}
      fontWeight={"400"}
      bgColor={"white"}
      pointerEvents={"none"}
      cursor={"default"}
      justifyContent={"flex-start"}
      minH={"2rem !important"}
    >
      {props.name}
    </Button>
  );
};
