import { useState } from "react";
import data from "../../../config/config.json";
import {
  Image,
  Text,
  Center,
  Container,
  Button,
  Stack,
  FormControl,
  FormErrorMessage,
  Link,
  Input,
  Spinner,
  Flex,
  Box,
} from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";
import { authentication } from "../../services/authentication";
import { useNavigate } from "react-router-dom";

export const SignupPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [badEmailOrPassword, setBadEmailOrPassword] = useState<boolean>(false);
  const [isVerificationNoticeShowing, setShowVerificationNotice] =
    useState<boolean>(false);

  const [error, setError] = useState("");

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const password = event.currentTarget.value;
    setBadEmailOrPassword(true);
    if (password.length < 8) {
      setError("Password must have at least 8 characters");
    } else if (password.search(/[a-z]/) < 0) {
      setError("Password needs a lower case letter");
    } else if (password.search(/[A-Z]/) < 0) {
      setError("Password needs an uppser case letter");
    } else if (password.search(/[0-9]/) < 0) {
      setError("Password needs a number");
    } else if (password.search(/[!@#$%^&*]/) < 0) {
      setError("Password needs an special character");
    } else {
      setError("");
      setBadEmailOrPassword(false);
      setPassword(event.currentTarget.value);
    }
  };

  const handleLoggingButtonCLick = async () => {
    if (password !== confirmPassword) {
      setError("Password and Confirmation Password are not equal");
      setBadEmailOrPassword(true);
      return;
    }
    try {
      setError("");
      setIsLoading(true);
      await authentication.createWithUserAndPassword(email, password);
      setShowVerificationNotice(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.log(error);
      setError("There was an error creating the account");
    } finally {
      setIsLoading(false);
    }
  };

  const renderVerificationNotice = () => {
    return <div>We sent you a verification email...</div>;
  };

  const renderForm = () => {
    return (
      <Stack spacing="8">
        <Stack spacing="6" align="center">
          <Image
            className="RepLoginImage"
            width="12rem"
            height="5rem"
            objectFit="contain"
            src={data.icon}
            alt={data.companyName}
          />
        </Stack>

        <Stack spacing="6">
          <Stack spacing="4">
            <FormControl isInvalid={!!error}>
              {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
            </FormControl>
            <Input
              isInvalid={badEmailOrPassword}
              isDisabled={isLoading}
              placeholder="Email"
              onChange={(event) => {
                setEmail(event.currentTarget.value);
              }}
            ></Input>
            <Input
              isDisabled={isLoading}
              isInvalid={badEmailOrPassword}
              type="password"
              placeholder="Password"
              onChange={(event) => onPasswordChange(event)}
            ></Input>
            <Input
              isDisabled={isLoading}
              isInvalid={badEmailOrPassword}
              type="password"
              placeholder="Confirm Password"
              onChange={(event) => {
                setConfirmPassword(event.currentTarget.value);
              }}
            ></Input>
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              <Flex gap={3} direction={"column"}>
                <Button
                  isDisabled={!email || !password || !confirmPassword}
                  onClick={() => handleLoggingButtonCLick()}
                >
                  Sign Up
                </Button>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  Have an account?
                  <Button
                    variant={"ghost"}
                    _hover={{ bg: "transparent" }}
                    padding={2}
                    onClick={() => navigate("/login")}
                  >
                    Sign In
                  </Button>
                </Box>
              </Flex>
            )}
          </Stack>
        </Stack>

        <Stack spacing="0.5" align="center">
          <Text fontSize="sm" color="muted">
            Having trouble signing up?
          </Text>
          <Link href={`${data.contactUri}`}>
            <Button
              variant="link"
              leftIcon={<PhoneIcon />}
              color={data.primaryColor}
              size="sm"
            >
              Contact us
            </Button>
          </Link>
        </Stack>
        <Text fontSize="xs" color="subtle" textAlign="center">
          By continuing, you acknowledge that you have read, understood, and
          agree to our terms and condition
        </Text>
      </Stack>
    );
  };

  return (
    <Center height="100vh">
      <Container
        maxW="md"
        bgGradient={{
          sm: `linear(to-r, ${data.linearGradient[0]}, ${data.linearGradient[1]})`,
        }}
        py={{ base: "0", sm: "8" }}
        px={{ base: "4", sm: "10" }}
        bg={{ base: "transparent", sm: "bg-surface" }}
        boxShadow={{ base: "none", sm: "xl" }}
        borderRadius={{ base: "none", sm: "xl" }}
      >
        {isVerificationNoticeShowing
          ? renderVerificationNotice()
          : renderForm()}
      </Container>
    </Center>
  );
};
