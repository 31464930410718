import {
  Box,
  Text,
  Flex,
  Input,
  Textarea,
  Button,
  Image,
  useToast,
  Skeleton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import "./EditPalletPage.scss";
import { storageService } from "../../services/storage-service";
import { IStorage, IStorageDocument } from "../../data/product";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { useNavigate } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";

const Page = () => {
  const unitId = window.location.href.toString().split("edit-pallet/")[1];
  const [description, setDescription] = useState("");
  const [images, setImages] = useState<string[]>([]);
  const [documents, setDocuments] = useState<IStorageDocument[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [loadedPallet, loadingPallet] = useDataFetcher({
    serviceCall: () => storageService.getStorageByUnitId(unitId),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });
  const [pallet, setPallet] = useState<IStorage | undefined>();
  const [signature, setSignature] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sigCanvas = useRef<ReactSignatureCanvas>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (loadedPallet && loadedPallet.current) {
      setPallet(loadedPallet);
      setDescription(loadedPallet.current.description);
      setSignature(loadedPallet.current.signature);
      if (typeof loadedPallet.current.photo === "string") {
        setImages([loadedPallet.current.photo]);
      } else {
        if (
          loadedPallet.current.photo &&
          loadedPallet.current.photo.length > 0
        ) {
          setImages([...loadedPallet.current.photo]);
        }
      }
      if (
        loadedPallet.current.document &&
        !Array.isArray(loadedPallet.current.document)
      ) {
        setDocuments([loadedPallet.current.document]);
      } else {
        if (
          loadedPallet.current.document &&
          loadedPallet.current.document.length > 0
        ) {
          setDocuments([...loadedPallet.current.document]);
        }
      }
    }
  }, [loadedPallet]);

  const removeDocument = (index: number) => {
    const tempDocuments = documents.filter(
      (document) => document.documentName !== documents[index].documentName
    );
    setDocuments(tempDocuments);
  };

  const removeImage = (index: number) => {
    const tempImages = images.filter((image) => image !== images[index]);
    setImages(tempImages);
  };

  const toBase64 = (file: File) =>
    new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString());
      reader.onerror = reject;
    });

  const isSubmitButtonDisabled = () => {
    const isDescriptionEqual =
      JSON.stringify(pallet?.current?.description) ===
      JSON.stringify(description);

    let isImagesEqual = true;
    if (JSON.stringify(pallet?.current?.photo)) {
      isImagesEqual =
        JSON.stringify(pallet?.current?.photo) === JSON.stringify(images);
    } else {
      isImagesEqual = !(images.length > 0);
    }

    let isDocumentsEqual = true;
    if (JSON.stringify(pallet?.current?.document)) {
      isDocumentsEqual =
        JSON.stringify(pallet?.current?.document) === JSON.stringify(documents);
    } else {
      isDocumentsEqual = !(documents.length > 0);
    }

    return (
      (isDescriptionEqual && isImagesEqual && isDocumentsEqual) ||
      isLoading ||
      loadingPallet
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await storageService.updateStorageElement(
        {
          client_id: pallet?.current?.client_id as string,
          description: description,
          entry_date: pallet?.current?.entry_date as string,
          photo: images,
          document: documents,
          signature: signature,
          status: "BUSY",
        },
        pallet?.unit_id as string
      );
      toast({
        title: "Pallet updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      navigate("/home");
    } catch (e: any) {
      toast({
        title: e.message,
        description: "check file size",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    setIsLoading(false);
  };

  const getPageContent = () => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="EditPalletPage"
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "2rem", xl: "2rem" }}
          maxW={"1500px"}
        >
          <Text fontSize={"3xl"} fontWeight={"bold"}>
            Pallet Information
          </Text>
          <Box>
            <Skeleton isLoaded={!loadingPallet && !!pallet}>
              <Input
                fontSize={"xl"}
                value={pallet?.current?.client_id}
                variant={"filled"}
                placeholder="Client Email"
                height={"3rem"}
                readOnly
              />
            </Skeleton>
          </Box>
          <Flex gap={"2rem"}>
            <Skeleton isLoaded={!loadingPallet && !!pallet} w={"100%"}>
              <Input
                fontSize={"xl"}
                value={pallet?.unit_id}
                variant={"filled"}
                placeholder="Unit"
                height={"3rem"}
                readOnly
              />
            </Skeleton>
            <Skeleton isLoaded={!loadingPallet && !!pallet} w={"100%"}>
              <Input
                fontSize={"xl"}
                type="datetime-local"
                value={pallet?.current?.entry_date}
                variant={"filled"}
                placeholder="Entry Date"
                height={"3rem"}
                readOnly
              />
            </Skeleton>
          </Flex>
          <Flex gap={"2rem"} flexDir={"column"} paddingTop={"2rem"}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>
              Description
            </Text>
            <Skeleton isLoaded={!loadingPallet && !!pallet}>
              <Textarea
                fontSize={"xl"}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                variant={"filled"}
                size="lg"
                height={"20rem"}
              />
            </Skeleton>
          </Flex>
          <Flex gap={"2rem"} flexDir={"column"} paddingTop={"2rem"}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>
              Photos
            </Text>
            <Flex justifyContent={"space-between"}>
              <Button
                bg={"#DABF75"}
                w={"100px"}
                h={"250px"}
                onClick={() =>
                  document.getElementById("HomePageImageUploader")?.click()
                }
              >
                <Input
                  id="HomePageImageUploader"
                  type="file"
                  hidden
                  onClick={(event) => (event.currentTarget.value = "")}
                  onChange={async (event) => {
                    if (event.target.files && event.target.files?.length > 0) {
                      const temp = await toBase64(event.target.files[0]);
                      setImages([...images, temp]);
                    }
                  }}
                />
                <AddIcon color={"white"} />
              </Button>
              <Box w={"25%"} h={"250px"} bg={"#edf2f6"} position={"relative"}>
                {images.length > 0 && (
                  <>
                    <Image className="EditPalletPageImage" src={images[0]} />
                    <Button
                      className="EditPalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeImage(0)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Box>
              <Box w={"25%"} h={"250px"} bg={"#edf2f6"} position={"relative"}>
                {images.length > 1 && (
                  <>
                    <Image className="EditPalletPageImage" src={images[1]} />
                    <Button
                      className="EditPalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeImage(1)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Box>
              <Box w={"25%"} h={"250px"} bg={"#edf2f6"} position={"relative"}>
                {images.length > 2 && (
                  <>
                    <Image className="EditPalletPageImage" src={images[2]} />
                    <Button
                      className="EditPalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeImage(2)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Box>
            </Flex>
          </Flex>
          <Flex gap={"2rem"} flexDir={"column"} paddingTop={"2rem"}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>
              Documents
            </Text>
            <Flex justifyContent={"space-between"}>
              <Button
                bg={"#DABF75"}
                w={"100px"}
                h={"250px"}
                onClick={() =>
                  document.getElementById("HomePageFileUploader")?.click()
                }
              >
                <Input
                  id="HomePageFileUploader"
                  type="file"
                  hidden
                  onClick={(event) => (event.currentTarget.value = "")}
                  onChange={async (event) => {
                    if (event.target.files && event.target.files?.length > 0) {
                      const temp = await toBase64(event.target.files[0]);
                      setDocuments([
                        ...documents,
                        {
                          document: temp,
                          documentName: event.target.files[0].name,
                        },
                      ]);
                    }
                  }}
                />
                <AddIcon color={"white"} />
              </Button>
              <Flex className="EditPalletPageDocumentContainer">
                {documents.length > 0 && (
                  <>
                    <a
                      style={{ fontSize: "1rem" }}
                      download={documents[0].documentName}
                      href={documents[0].document}
                    >
                      {documents[0].documentName}
                    </a>
                    <Button
                      className="EditPalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeDocument(0)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Flex>
              <Flex className="EditPalletPageDocumentContainer">
                {documents.length > 1 && (
                  <>
                    <a
                      style={{ fontSize: "1rem" }}
                      download={documents[1].documentName}
                      href={documents[1].document}
                    >
                      {documents[1].documentName}
                    </a>
                    <Button
                      className="EditPalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeDocument(1)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Flex>
              <Flex className="EditPalletPageDocumentContainer">
                {documents.length > 2 && (
                  <>
                    <a
                      style={{ fontSize: "1rem" }}
                      download={documents[2].documentName}
                      href={documents[2].document}
                    >
                      {documents[2].documentName}
                    </a>
                    <Button
                      className="EditPalletPageDeleteButton"
                      _hover={{
                        background: "transparent",
                      }}
                      onClick={() => removeDocument(2)}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent={"start"} gap={"2rem"}>
            <Button
              padding={"2.4rem"}
              w={"min-content"}
              color={"white"}
              bg={"#DABF75"}
              onClick={onOpen}
            >
              Open Signature Pad
            </Button>
            {signature && (
              <Box border={"1px solid black"} w={"125px"} h={"75px"}>
                <Image
                  src={signature}
                  w={"100%"}
                  h={"100%"}
                  objectFit={"contain"}
                />
              </Box>
            )}
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
            >
              <ModalOverlay />
              <ModalContent
                maxW={"1000px"}
                w={"80%"}
                h={"80%"}
                maxHeight={"600px"}
              >
                <ModalHeader>Signature</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{
                      className: "sigCanvas",
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      setSignature(
                        sigCanvas.current
                          ?.getTrimmedCanvas()
                          .toDataURL("image/png") || ""
                      );
                      onClose();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    mr={3}
                    onClick={() => {
                      setSignature("");
                      sigCanvas.current?.clear();
                    }}
                  >
                    Clear
                  </Button>
                  <Button mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
          <Flex w={"100%"} justifyContent={"end"} paddingBottom={"2rem"}>
            <Button
              w={"100%"}
              _hover={{
                background: "#DABF75",
              }}
              height={"5rem"}
              fontSize={"xl"}
              bg={"#DABF75"}
              color={"white"}
              isDisabled={isSubmitButtonDisabled()}
              onClick={() => handleSubmit()}
            >
              {`Submit ${isLoading ? "(In Progress...)" : ""}`}
            </Button>
          </Flex>
        </Box>
      </Box>
    );
  };

  return getPageContent();
};

export const EditPalletPage = withAuthProtection(Page);
