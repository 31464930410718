import {
  Box,
  Text,
  Flex,
  Grid,
  Skeleton,
  Icon,
  Button,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { ClientCard } from "./components/ClientCard";
import { IoCloudOfflineOutline } from "react-icons/io5";
import "./ClientsPage.scss";
import { clientsService } from "../../services/clients-service";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { IClient } from "../../data/product";

const styleTemplateColumns = {
  base: "repeat(1, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(3, 1fr)",
};

const styleColumnGap = {
  base: "1rem",
  xl: "2.5rem",
  "2xl": "5rem",
};

const Page = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [clients, loadingClients] = useDataFetcher({
    serviceCall: () => clientsService.getClients(),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
    dependencies: [isLoading],
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");

  const handleOnClose = () => {
    setFirstName("");
    setLastName("");
    setBusinessEmail("");
    setBusinessAddress("");
    onClose();
  };
  const handleOnCreateClient = async () => {
    const client: IClient = {
      name: firstName,
      lastName: lastName,
      businessEmail: businessEmail,
      businessAddress: businessAddress,
    };
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!businessEmail.match(validRegex)) {
      toast({
        title: "Business email is not a valid email",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setIsLoading(true);
    try {
      const user = await clientsService.getClientByEmail(businessEmail);
      if (user.length > 0) {
        toast({
          title: "There is already a client with that email",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        await clientsService.createClient(client);
        toast({
          title: "Success",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        handleOnClose();
      }
    } catch (e: any) {
      toast({
        title: e.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    setIsLoading(false);
  };

  const renderClients = () => {
    if (!clients || clients.length === 0) {
      return (
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          gap={"0.5rem"}
          paddingTop={"2rem"}
        >
          <Icon boxSize={12} as={IoCloudOfflineOutline} />
          <Text>There is no data to display</Text>
        </Flex>
      );
    }

    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {clients.map((client) => {
          return <ClientCard client={client} />;
        })}
      </Grid>
    );
  };

  const renderSkeletons = () => {
    return (
      <Grid
        templateColumns={styleTemplateColumns}
        rowGap={"2rem"}
        columnGap={styleColumnGap}
      >
        {new Array(12).fill(0).map(() => {
          return <Skeleton h={"14rem"} w={"100%"} />;
        })}
      </Grid>
    );
  };

  const getPageContent = () => {
    return (
      <Box
        h={"100vh"}
        overflowY={"auto"}
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          className="ClientsPage"
          display={"flex"}
          flexDirection={"column"}
          gap={{ base: "2rem", xl: "4rem" }}
          maxW={"1500px"}
        >
          <Flex alignItems={"center"} gap={"1rem"}>
            <Text fontSize="5xl" fontWeight={700}>
              Clients
            </Text>
            <Button variant="grey" onClick={onOpen}>
              <AddIcon />
            </Button>
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              closeOnOverlayClick={false}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Create client</ModalHeader>
                <ModalCloseButton onClick={() => handleOnClose()} />
                <ModalBody pb={6}>
                  <FormControl>
                    <FormLabel>First name</FormLabel>
                    <Input
                      placeholder="First name"
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>Last name</FormLabel>
                    <Input
                      placeholder="Last name"
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>Business email</FormLabel>
                    <Input
                      placeholder="Business email"
                      onChange={(event) => setBusinessEmail(event.target.value)}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>Business address</FormLabel>
                    <Input
                      placeholder="Business address"
                      onChange={(event) =>
                        setBusinessAddress(event.target.value)
                      }
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => handleOnCreateClient()}
                    isDisabled={
                      isLoading ||
                      !firstName ||
                      !lastName ||
                      !businessAddress ||
                      !businessEmail
                    }
                  >
                    Create {isLoading && "(In Progress...)"}
                  </Button>
                  <Button onClick={() => handleOnClose()}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
          <Box>
            <Flex flexDir={"column"} gap={"2rem"} paddingBottom={"2rem"}>
              {loadingClients ? renderSkeletons() : <>{renderClients()}</>}
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  };

  return getPageContent();
};

export const ClientsPage = withAuthProtection(Page);
