import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HiDocumentRemove, HiHome } from "react-icons/hi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FaPallet, FaUsers } from "react-icons/fa";
import { MdVideoLibrary } from "react-icons/md";

interface NavButtonProps extends ButtonProps {
  icon: string;
  label: string;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, ...buttonProps } = props;
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const getIcon = (key: string) => {
    if (key === "home") {
      return HiHome;
    } else if (key === "receivePallet") {
      return FaPallet;
    } else if (key === "dischargePallet") {
      return HiDocumentRemove;
    } else if (key === "clients") {
      return FaUsers;
    } else if (key === "training") {
      return MdVideoLibrary;
    } else {
      return HiOutlineArrowSmRight;
    }
  };
  return (
    <Button
      variant="grey"
      justifyContent={isDesktop ? "start" : "center"}
      {...buttonProps}
    >
      <HStack spacing="3">
        <Icon as={getIcon(icon)} />
        {isDesktop && <Text>{label}</Text>}
      </HStack>
    </Button>
  );
};
